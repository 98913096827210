<template>
    <div class="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 features__content">
                    <h2 class="features__title">
                        {{ $t('At Accounts we seek to give you the best experience') }}
                    </h2>
                    <p class="features__text">
                        {{ $t('We offer you the best options so that you can have better control.') }}
                    </p>
                    <ul class="features__list">
                        <li class="features__list-item">
                            <div class="features__list-icon features__list-icon--purple">
                                <span class="fas fa-key"></span>
                            </div>
                            <div>
                                <h6 class="features__list-title">
                                    {{ $t('Password management') }}
                                </h6>
                                <p class="features__list-text">
                                    {{ $t('With Accounts, your credentials are available in one place to be used in different applications.') }}
                                </p>
                            </div>
                        </li>
                        <li class="features__list-item">
                            <div class="features__list-icon features__list-icon--blue">
                                <span class="fas fa-building"></span>
                            </div>
                            <div>
                                <h6 class="features__list-title">
                                    {{ $t('Business administrator') }}
                                </h6>
                                <p class="features__list-text">
                                    {{ $t('With Accounts you can quickly and easily manage the companies related to your account.') }}
                                </p>
                            </div>
                        </li>
                        <li class="features__list-item">
                            <div class="features__list-icon features__list-icon--green">
                                <span class="fas fa-shield-alt"></span>
                            </div>
                            <div>
                                <h6 class="features__list-title">
                                    {{ $t('Security') }}
                                </h6>
                                <p class="features__list-text">
                                    {{ $t('With Accounts your credentials are stored securely and are available to be used in different applications.') }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6">
                    <img src="@/assets/images/home/authentication.svg" class="img-fluid" alt="Tendencys" loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'features',
};
</script>

<style lang="scss">
.features {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #F5F6F7;

    @media(max-width: 768px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &__content {
        padding-right: 40px;
    }

    &__title {
        font-weight: 600;
        font-size: 35px;
        color: #202225;

        @media(max-width: 768px) {
            font-size: 28px;
            margin-bottom: 35px;
            text-align: center;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        color: #787F84;
        margin-bottom: 50px;

        @media(max-width: 768px) {
            text-align: center;
        }
    }

    &__list {
        padding-left: 0;
        list-style: none;

        @media(max-width: 992px) {
            margin-bottom: 30px;
        }
    }

    &__list-item {
        display: flex;

        &:not(:last-child) {
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 35px;
            padding-bottom: 35px;
        }
    }

    &__list-icon {
        height: 60px;
        width: 60px;
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        background-color: #eeeeee;
        color: #ffffff;
        border-radius: 15px;
        margin-right: 25px;
        font-size: 20px;

        &--purple {
            background-color: #C195C0;
        }
        &--blue {
            background-color: #7AD6D9;
        }
        &--green {
            background-color: #A7CA85;
        }
    }

    &__list-title {
        font-size: 18px;
        color: #202225;
        font-weight: 600;
    }

    &__list-text {
        font-size: 16px;
        color: #434343;
        margin-bottom: 0;
    }
}
</style>
